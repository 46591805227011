import React from 'react'
import { graphql } from 'gatsby'
import BannerTitleOnlyBlock from 'components/blocks/BannerTitleOnlyBlock'
import SingleImageProductBlock from 'components/blocks/SingleImageProductBlock'
import BodyBlock from 'components/blocks/BodyBlock'
import SingleImageBlock from 'components/blocks/SingleImageBlock'
import MoreItemsBlock from 'components/blocks/MoreItemsBlock'
import { getNextItems } from 'js/utils'
import { useLocalization } from 'gatsby-theme-i18n'
import { tr } from 'content/strings'

export default function ProductPost({ data }) {
  const { locale } = useLocalization()
  const page = data.strapiProduct
  if (!page) return false
  const { productPostBuilder, productCollection } = page
  const customBanner = { pretitle: tr('products', locale) }

  let products = data.allStrapiProduct.nodes
  let nextProducts
  if (products) {
    if (page.productCollection) {
      products = products.filter(
        (product) =>
          product.productCollection?.slug === page.productCollection.slug
      )
    }
    nextProducts = getNextItems(products, page.slug, 4)
  }

  return (
    <div className='page-product-post'>
      {/* <BannerTitleOnlyBlock
        content={customBanner}
        padTop={true}
        lineBottom={true}
        marginBottom={true}
      /> */}
      <SingleImageProductBlock content={{ page }} />
      {productPostBuilder && <Post content={productPostBuilder} />}
      {nextProducts && (
        <MoreItemsBlock
          items={nextProducts}
          version='product'
          parent={productCollection}
        />
      )}
    </div>
  )
}

const Post = ({ content }) => {
  const { bodyBlock, singleImageBlock } = content

  return (
    <>
      {bodyBlock && <BodyBlock content={bodyBlock} isDiamond={true} />}
      {singleImageBlock && (
        <SingleImageBlock content={singleImageBlock} isBigger={true} />
      )}
    </>
  )
}

// strapiDiamondsPage(locale: { eq: $locale }) {
//   title
// }
export const pageQuery = graphql`
  query ($locale: String!, $slug: String!) {
    strapiProduct(slug: { eq: $slug }, locale: { eq: $locale }) {
      slug
      title
      seo {
        title
        description
      }
      image {
        localFile {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
      productPostBuilder {
        slideshowBlock {
          images {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
        bodyBlock {
          title
          body
        }
        singleImageBlock {
          image {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
      }
      productCollection {
        slug
        title
      }
    }
    allStrapiProduct(filter: { locale: { eq: $locale } }) {
      nodes {
        title
        excerpt
        slug
        image {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
        productCollection {
          slug
          title
        }
      }
    }
  }
`
